import React from 'react';

export enum COMPANY_INFORMATION_ELEMENTS {
  NAME = 'name',
  USP = 'uniqueSellingPoint',
  BENEFITS = 'benefits',
  PRODUCT = 'description'
}

export enum COMMUNICATION_ELEMENTS {
  SPEECH = 'speech',
  WRITING_STYLE = 'writingStyle'
}

export const AiFieldsRules = {
  textFields: [
    { required: true, message: 'Bitte gib einen Wert an.' },
    {
      min: 3,
      max: 200,
      whitespace: true,
      message: 'Ihr Text muss mindestens 3 und max. 200 Zeichen lang.'
    }
  ],
  generalTags: [
    { required: true, message: 'Bitte gib einen Wert an.' },
    {
      validator: (_: any, val: any) => {
        if (val?.length > 5) {
          return Promise.reject(new Error('Es dürfen maximal 5 Lappen angegeben werden.'));
        }
        return Promise.resolve();
      }
    }
  ]
};

export type CompanyDetailsOptions =
  | COMPANY_INFORMATION_ELEMENTS.USP
  | COMPANY_INFORMATION_ELEMENTS.BENEFITS
  | COMPANY_INFORMATION_ELEMENTS.PRODUCT;

export type CommunicationDataOptions =
  | COMMUNICATION_ELEMENTS.SPEECH
  | COMMUNICATION_ELEMENTS.WRITING_STYLE;

export const getAiDataOptions = (key: CompanyDetailsOptions) => {
  const obj = {
    [COMPANY_INFORMATION_ELEMENTS.USP]: {
      icon: <></>,
      options: []
    },
    [COMPANY_INFORMATION_ELEMENTS.BENEFITS]: {
      icon: <></>,
      options: []
    },
    [COMPANY_INFORMATION_ELEMENTS.PRODUCT]: {
      icon: <></>,
      options: []
    }
  };

  return obj[key];
};

export const getCommunicationDataHelper = (key: CommunicationDataOptions) => {
  const obj = {
    [COMMUNICATION_ELEMENTS.WRITING_STYLE]: {
      icon: <></>,
      options: [
        { label: 'Formal', value: 'Formal' },
        { label: 'Jugendlich', value: 'Youth' },
        { label: 'Fröhlich', value: 'Happy' },
        { label: 'Visionär', value: 'Visionary' }
      ]
    },
    [COMMUNICATION_ELEMENTS.SPEECH]: {
      options: [
        { label: 'Duzen', value: 'Duzen' },
        { label: 'Siezen', value: 'Siezen' }
      ]
    }
  };

  return obj[key];
};
