import { Col, Collapse, Form, Row } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../../../redux/hooks';
import { hashFormItemsTargetGroup } from '../../../AdJobBuilder/components/sharedUI/FormItemsCustom';
import { ExperienceDuration } from '../../../AdJobBuilder/helper/AdJobHelper';
import BuilderValidator from '../../../container/BuilderValidator';
import {
  editFunnelSavedIndicator,
  setTargetAudience,
  useIsAiSettingDuplicated,
  useTargetAudience
} from '../../../redux/builderSlice';
import DuplicateAiSettingsAlert from '../utils/DuplicateAiSettingsAlert';
import {
  TARGET_GROUP_ELEMENTS,
  getOptionsValuesInListAi,
  getTargetGroupOptions
} from './components/TargetGroupHelper';
import { predefinedGoalOptions } from '../utils/predefined-options';

const { Panel } = Collapse;

interface TargetGroupTabProps {
  showBottomImage?: boolean;
}

export default function TargetGroupTab({}: TargetGroupTabProps): JSX.Element {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { age, goals, skills, education, experience, experienceDuration } = useTargetAudience();

  const handleChange = (value: any, identifier: any) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [identifier]: value });
    if (!identifier || value === undefined) return;
    dispatch(editFunnelSavedIndicator(false));
    dispatch(setTargetAudience({ value, identifier }));
  };

  const isFunnelDuplicated = useIsAiSettingDuplicated();

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Row className="builder__settings-sidebar__row">
        <Col span={24} className="px-4">
          <Form
            name="general_settings_form"
            layout="vertical"
            form={form}
            scrollToFirstError={true}
            initialValues={{
              goals,
              skills,
              age,
              education,
              experience,
              experienceDuration
            }}
          >
            <Collapse className="w-100" accordion expandIconPosition="right" bordered={false}>
              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Alter</h6>
                    <p className="m-0 settings-subtitle">
                      {getOptionsValuesInListAi(
                        TARGET_GROUP_ELEMENTS.AGE,
                        [age as string],
                        getTargetGroupOptions(TARGET_GROUP_ELEMENTS.AGE).options
                      ) || `Wie alt sollte dein Wunschmitarbeiter sein?`}
                    </p>
                  </div>
                }
                key={1}
              >
                {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.AGE](age, handleChange)}
              </Panel>
              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Ziele & Wünsche</h6>
                    <p className="m-0 settings-subtitle">
                      {goals
                        .map(
                          single => predefinedGoalOptions.find(goal => goal.value === single)?.label
                        )
                        ?.join(', ') || `Was möchte dein Wunschmitarbeiter erreichen?`}
                    </p>
                  </div>
                }
                key={3}
              >
                {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.GOALS](goals, handleChange)}
              </Panel>
              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Fähigkeiten</h6>
                    <p className="m-0 settings-subtitle">
                      {skills?.join(', ') ||
                        `Welche Fähigkeiten oder Kenntnisse sollte dein Mitarbeiter beherrschen?`}
                    </p>
                  </div>
                }
                key={4}
              >
                {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.SKILLS](skills, handleChange)}
              </Panel>
              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Erfahrung</h6>
                    <p className="m-0">
                      {`Erfahrung ${experience} ${getOptionsValuesInListAi(
                        TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION,
                        [experienceDuration || ExperienceDuration.YEARS],
                        getTargetGroupOptions(TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION).options
                      )}`}
                    </p>
                  </div>
                }
                key={5}
              >
                {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.EXPERIENCE](
                  experience,
                  handleChange
                )}
                {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION](
                  experienceDuration,
                  handleChange
                )}
              </Panel>
              <Panel
                header={
                  <div>
                    <h6 className="settings-title__required">Bildungsgrad</h6>
                    <p className="m-0">
                      {getOptionsValuesInListAi(
                        TARGET_GROUP_ELEMENTS.EDUCATION,
                        education || [],
                        getTargetGroupOptions(TARGET_GROUP_ELEMENTS.EDUCATION).options
                      ) || 'Wähle die Ausbildung der Zielgruppe aus.'}
                    </p>
                  </div>
                }
                key={6}
              >
                {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.EDUCATION](education, handleChange)}
              </Panel>
            </Collapse>
          </Form>

          {isFunnelDuplicated && <DuplicateAiSettingsAlert />}
        </Col>
      </Row>
    </Col>
  );
}
