import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Popover, Progress, Row, Switch, message } from 'antd';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import OptionsPicker from '../../../sharedUI/OptionsPicker';
import TextArea from 'antd/lib/input/TextArea';
import BuilderSettingsTogglePro from '../../../sharedUI/BuilderSettingsTogglePro';
import {
  AI_PROMPT_TEXT_TYPES,
  GET_AI_TEXT_RESPONSE
} from '../../../../hooks/ai-conversation/getAiTextResponse';
import {
  cleanHtml,
  getSelectedHtmlInDraftJsEditorState,
  getSelectedTextInDraftJsEditorState,
  replaceTextInDraftJsEditorStateUsingHtml
} from '../helpers/textManipulators';
import { useEditor, useNode } from '@craftjs/core';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { setGeneralSettingsTabView, useEmptyGeneralSettings } from '../../../../redux/builderSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { updateUserSubscriptionInfo, useSubscriptionInfo } from '../../../../../UI/redux/userSlice';
import { formatNumber } from '../../../../AdJobBuilder/helper/validateFunnelJobPresets';
import { GET_AI_TEXT_RESPONSE_FOR_NODE } from '../../../../hooks/ai-conversation/getAiTextResponseForNode';
import DefaultSmallLoader from '../../../../../SharedUI/components/DefaultSmallLoader';
import {
  AiTextSettingsProps,
  commandPlaceholderByTextType,
  messages,
  textTypeDefaultLength,
  textTypeOptions,
  tokensToWords
} from '../helpers/aiTextSettingHelpers';
import ConditionalTooltip from '../../../../../SharedUI/components/ConditionalTooltip';
import { convertDraftStateToHtml, getSiblingNodeNames } from '../../../../helper/craftJs';
import { LeadsQualifiers } from '../../../../config/craftElements';
import { AiTextMessages } from '../../../../../config/messages';
import MinMaxNumberInput from '../../../../../SharedUI/components/MinMaxNumberInput';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../../../UI/utils/mixpanel';
import Title from 'antd/lib/typography/Title';
import { RetweetOutlined, EditOutlined, SelectOutlined } from '@ant-design/icons';
import { GeneralSettingsTabView } from '../../../../interfaces/builderSliceTypes';
import GeneralSettingsModal from '../../../../AdJobBuilder/components/sharedUI/GeneralSettingsModal';

const AiTextSettings = ({ editorState }: AiTextSettingsProps) => {
  const {
    actions: { setProp },
    nodeId,
    props,
    nodeName,
    parentId,
    currentNode
  } = useNode(node => {
    return {
      nodeId: node.id,
      props: node.data.props,
      nodeName: node.data.name,
      parentId: node.data.parent,
      currentNode: node
    };
  });

  const { query } = useEditor();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const emptyGeneralSettings = useEmptyGeneralSettings();
  const isSomeValueMissing = !!emptyGeneralSettings.length;
  const { status, maxChatAiTokens, remainingChatAiTokens } = useSubscriptionInfo();
  const currentChatAiTokens = maxChatAiTokens - remainingChatAiTokens;
  const currentChatAiUsageProgress =
    formatNumber(Math.round(currentChatAiTokens * 0.75)) +
    '/' +
    formatNumber(Math.round(maxChatAiTokens * 0.75));
  const currentChatAiUsagePercentage = tokensToWords((currentChatAiTokens * 100) / maxChatAiTokens);

  const isQuotaExceeded = remainingChatAiTokens <= 5;
  const isInTrial = status === 'TRIALING';

  const [promptText, setPromptText] = useState('');
  const [shouldGenerateMore, setShouldGenerateMore] = useState(false);
  const [isUsingSelectedText, setIsUsingSelectedText] = useState(false);
  const selectedTypeLimits = textTypeDefaultLength.find(el => el.type === props.textTypeOption);
  const [textMaxLimit, setTextMaxLimit] = useState<number>(selectedTypeLimits?.max || 50);
  const [textMinLimit, setTextMinLimit] = useState<number>(selectedTypeLimits?.min || 20);
  const [previousSelection, setPreviousSelection] = useState('');
  const [allGeneratedTexts, setAllGeneratedTexts] = useState<string[]>([]);
  const [selectedTemplateText, setSelectedTemplateText] = useState('');
  const [currentConversationId, setCurrentConversationId] = useState('');
  const [textGotGenerated, setTextGotGenerated] = useState(false);
  const [freeText, setFreeText] = useState('');
  const [currentTextTypeOption, setCurrentTextTypeOption] = useState<AI_PROMPT_TEXT_TYPES>(
    props.textTypeOption
  );
  const [isGeneralSettingsModalOpen, setIsGeneralSettingsModalOpen] = useState(false);

  const lastGeneratedRef = useRef<string[]>([]);

  const selectedText = getSelectedTextInDraftJsEditorState(editorState) || '';
  const selectedTextLength = selectedText?.length;
  const shouldUseSelectedText = !!selectedText && isUsingSelectedText;

  const [
    getAiTextResponse,
    { loading: textGenLoading, data: generated }
  ] = useLazyQuery(GET_AI_TEXT_RESPONSE, { fetchPolicy: 'no-cache' });

  const { data: existingGeneratedData, loading: existingGeneratedLoading } = useQuery(
    GET_AI_TEXT_RESPONSE_FOR_NODE,
    {
      variables: { input: { nodeId } },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    if (generated) {
      const newGeneratedTexts = generated?.getAiTextResponse?.map((text: string) => text) || [];
      lastGeneratedRef.current = newGeneratedTexts;
      setAllGeneratedTexts([...allGeneratedTexts, ...newGeneratedTexts]);
      setTextGotGenerated(true);
      const usedTokens = generated?.getAiTextResponse?.[0]?.totalTokens;
      const convId = generated?.getAiTextResponse?.[0]?.conversationId;
      if (usedTokens) {
        dispatch(
          updateUserSubscriptionInfo({ remainingChatAiTokens: remainingChatAiTokens - usedTokens })
        );
      }
      if (convId) setCurrentConversationId(convId);

      setTimeout(() => setTextGotGenerated(false), 5000);
    }
  }, [generated]);

  useEffect(() => {
    if (selectedTextLength > 1000) message.error(AiTextMessages.selectionTooLong);
  }, [selectedTextLength]);

  useEffect(() => {
    if (props.isLeadQualifier && !props.textTypeOption) {
      setCurrentTextTypeOption(AI_PROMPT_TEXT_TYPES.ASK);
      setProp((props: any) => (props.textTypeOption = AI_PROMPT_TEXT_TYPES.ASK));
    }
  }, [props.isLeadQualifier]);

  useEffect(() => {
    if (existingGeneratedData?.getAiTextResponseForNode?.length) {
      const { getAiTextResponseForNode } = existingGeneratedData;
      setAllGeneratedTexts(getAiTextResponseForNode?.map((text: string) => text) || []);
    }
  }, [existingGeneratedData?.getAiTextResponseForNode?.length]);

  const scrollToSuggestions = () => {
    const sidebarContainerElement = document.querySelector('.builder__settings-sidebar__container');
    const targetElement = (document.querySelector('#ai-suggestions-title') as HTMLElement)
      ?.offsetParent as HTMLElement;

    if (targetElement && sidebarContainerElement)
      sidebarContainerElement.scrollTop = targetElement.offsetTop;
  };

  useEffect(() => {
    scrollToSuggestions();
  }, [allGeneratedTexts.length]);

  const handleSetPromptText = (value: string) => {
    setPromptText(value);
    if (shouldGenerateMore) setShouldGenerateMore(false);
  };

  const handleTextGeneration = async (mode: 'new' | 'more' = 'new') => {
    const isGenerateMore = mode === 'more';
    const allSiblings = getSiblingNodeNames(query, parentId, nodeId);
    const allSiblingNames = allSiblings?.map((sibling: any) => sibling.displayName);
    const commonElements = LeadsQualifiers.filter(element => allSiblingNames.includes(element));
    const questionType = commonElements?.length ? commonElements?.[0] : nodeName;
    mixpanel.track(mixpanelEvents.TEXT_GENERATION_BUTTON);
    try {
      getAiTextResponse({
        variables: {
          input: {
            nodeId,
            ...(isUsingSelectedText ? { selectedText: html } : {}),
            maxLength: shouldUseSelectedText ? selectedTextLength + 10 : textMaxLimit,
            minLength: shouldUseSelectedText ? selectedTextLength - 10 : textMinLimit,
            promptMessage: promptText || '',
            type:
              currentTextTypeOption === AI_PROMPT_TEXT_TYPES.FREE
                ? freeText
                : currentTextTypeOption,
            questionType,
            ...((props.textTypeOption === currentTextTypeOption || isGenerateMore) &&
              currentConversationId && { conversationId: currentConversationId })
          }
        }
      });
      setProp((props: any) => (props.textTypeOption = currentTextTypeOption));
      setShouldGenerateMore(true);
    } catch (error) {
      message.error(AiTextMessages.generateFailed);
    }
  };

  const handleReplace = (newText: string) => {
    mixpanel.track(mixpanelEvents.CLICK_ON_GENERATED_TEXT_CARD);
    const replaceText = replaceTextInDraftJsEditorStateUsingHtml(
      editorState,
      newText,
      isUsingSelectedText,
      currentTextTypeOption
    );
    if (shouldUseSelectedText) setPreviousSelection(html);
    setProp((props: any) => (props.editorState = replaceText));
    setSelectedTemplateText(newText);
  };

  const handleTextTypeChange = (option: AI_PROMPT_TEXT_TYPES) => {
    setCurrentTextTypeOption(option);
    const selected = textTypeDefaultLength.find(el => el.type === option);
    setTextMaxLimit(selected?.max as number);
    setTextMinLimit(selected?.min as number);
  };

  const toggleGeneralSettingsModal = () => {
    setIsGeneralSettingsModalOpen(!isGeneralSettingsModalOpen);
  };

  const handleGoToAiSettings = () => {
    mixpanel.track(mixpanelEvents.GO_TO_AI_SETTING);
    dispatch(
      setGeneralSettingsTabView(
        emptyGeneralSettings?.[0] || GeneralSettingsTabView.COMPANY_INFORMATION
      )
    );
    navigate('/builder/grundeinstellungen');
  };

  const htmlState = getSelectedHtmlInDraftJsEditorState(editorState);

  const html = convertDraftStateToHtml(htmlState, props.exporter, 'html');

  const toRenderBasedOnMessageKey: any = {
    isSomeValueMissing: (
      <Button className="mt-2" type="primary" onClick={toggleGeneralSettingsModal}>
        Zu den KI-Einstellungen
      </Button>
    ),
    isQuotaExceeded: null
  };

  const aiTextDisableConditions = [
    { condition: isInTrial, messageKey: 'isInTrial' },
    { condition: isSomeValueMissing, messageKey: 'isSomeValueMissing' },
    { condition: isQuotaExceeded, messageKey: 'isQuotaExceeded' }
  ];

  const activeCondition = aiTextDisableConditions.find(({ condition }) => condition);

  const finalTextTypeOptions = props.isLeadQualifier
    ? textTypeOptions.map(el =>
        el.value != AI_PROMPT_TEXT_TYPES.ASK ? { ...el, disabled: true } : { ...el }
      )
    : textTypeOptions.filter(tt => tt.value !== AI_PROMPT_TEXT_TYPES.ASK);

  return (
    <div className="ai-text-settings mb-5">
      {activeCondition && (
        <div className="settings-missing-overlay">
          <h6 className="fill-text">{messages[activeCondition.messageKey]}</h6>
          {toRenderBasedOnMessageKey[activeCondition.messageKey]}
        </div>
      )}

      <Row className="builder__settings-sidebar__row">
        <Col span={24} className="mt-1 mb-3 px-4">
          <BuilderSettingsTitle title="Art" />
          <OptionsPicker
            options={finalTextTypeOptions}
            handleChange={value => {
              mixpanel.track(mixpanelEvents.TEXT_TYPE);
              handleTextTypeChange(value);
            }}
            value={currentTextTypeOption}
          />
        </Col>

        {shouldUseSelectedText && (
          <Col span={24} className="mb-3 px-4">
            <div className="marked-text" dangerouslySetInnerHTML={{ __html: cleanHtml(html) }} />
            <label className="small-text mr-2">
              <SelectOutlined /> Markierter Text
            </label>
          </Col>
        )}

        {currentTextTypeOption && (
          <Col span={24} className="mb-3">
            <BuilderSettingsTogglePro
              infoPopoverText={AiTextMessages.markTextToUseThis}
              disabled={!selectedText}
              title="Markierten Text verbessern"
              rightContent={
                <Switch
                  size="small"
                  checked={shouldUseSelectedText}
                  onChange={checked => setIsUsingSelectedText(checked)}
                />
              }
            />
          </Col>
        )}

        {currentTextTypeOption === AI_PROMPT_TEXT_TYPES.FREE && (
          <Col span={24} className="mb-3 px-4">
            <BuilderSettingsTitle title="Verwendungszweck (ca. 10 Wörter)" classNames="mt-1" />
            <TextArea
              value={freeText}
              onChange={e => setFreeText(e.target.value)}
              placeholder="z.B. vertrauenschaffender Text über dem Formular für die Dateneingabe-Seite"
              rows={3}
              maxLength={100}
            />
          </Col>
        )}

        {currentTextTypeOption && (
          <>
            <Col span={24} className="mb-3 px-4">
              <BuilderSettingsTitle title="Thema (ca. 5 Wörter)" classNames="mt-1" />
              <TextArea
                value={promptText}
                onChange={e => handleSetPromptText(e.target.value)}
                placeholder={commandPlaceholderByTextType[currentTextTypeOption]}
                rows={3}
                maxLength={1000}
              />
            </Col>
            <Col span={24} className="mb-3 px-4">
              <Button
                onClick={() =>
                  shouldGenerateMore ? handleTextGeneration('more') : handleTextGeneration()
                }
                className="w-100"
                type="primary"
                loading={textGenLoading}
                disabled={!(isUsingSelectedText || promptText?.length > 3)}
                icon={shouldGenerateMore ? <RetweetOutlined /> : <EditOutlined />}
              >
                {shouldGenerateMore && !textGenLoading ? 'Weitere Vorschläge' : 'Text generieren'}
              </Button>
            </Col>

            {!shouldUseSelectedText && (
              <Col span={24} className="mb-3 px-4">
                <MinMaxNumberInput
                  maxLimit={textMaxLimit}
                  minLimit={textMinLimit}
                  setMaxLimit={setTextMaxLimit}
                  setMinLimit={setTextMinLimit}
                  maximum={10000}
                  title="Wortanzahl"
                />
              </Col>
            )}

            <Col span={24} className="mb-3 px-4">
              {allGeneratedTexts?.length > 0 && (
                <>
                  <Title id="ai-suggestions-title" className="settings-title" level={4}>
                    Vorschläge
                  </Title>
                  <div>
                    {existingGeneratedLoading ? (
                      <div className="loader-container">
                        <DefaultSmallLoader loading={existingGeneratedLoading} />
                      </div>
                    ) : (
                      allGeneratedTexts
                        .slice(-3)
                        .reverse()
                        .map((text: any, idx: number) => {
                          const isLastTwo = lastGeneratedRef.current.includes(text);
                          const shortedTextLength = 150;
                          const displayText = `${cleanHtml(
                            text?.message?.slice(0, shortedTextLength)
                          )}${text?.message?.length > shortedTextLength ? '...' : ''}`;
                          const plainTextFromHtml = text?.message.replace(/<[^>]*>/g, ' ');

                          return (
                            <Row key={idx} className="py-2">
                              <Col span={24}>
                                <Card
                                  hoverable
                                  onClick={() => handleReplace(text?.message)}
                                  className={`generated-text-wrapper cursor-pointer ${
                                    isLastTwo && textGotGenerated ? 'focus-transition' : ''
                                  } p-1`}
                                >
                                  {selectedTemplateText === text.message && (
                                    <div className="template-block__inner__active-overlay">
                                      <CheckCircleOutlined />
                                    </div>
                                  )}
                                  <ConditionalTooltip
                                    disable={text?.message?.length <= shortedTextLength}
                                    title={plainTextFromHtml}
                                    placement="right"
                                  >
                                    <div dangerouslySetInnerHTML={{ __html: displayText }}></div>
                                  </ConditionalTooltip>
                                </Card>
                              </Col>
                            </Row>
                          );
                        })
                    )}
                  </div>
                </>
              )}
            </Col>
          </>
        )}

        {currentTextTypeOption && <Col span={24} className="mb-3 px-4">
          <Title className="settings-title" level={4}>
            {AiTextMessages.quota}
            <Popover
              className="default-popover"
              content={
                <div className="default-popover__content" style={{ maxWidth: 400 }}>
                  {AiTextMessages.quotaInfo}
                </div>
              }
              trigger="hover"
            >
              <InfoCircleOutlined />
            </Popover>
          </Title>
          <Progress
            percent={currentChatAiUsagePercentage}
            format={() => currentChatAiUsageProgress}
          />
        </Col> }

        <Col span={24} className="mb-3 px-4">
          <Alert
            message={
              currentTextTypeOption ? (<>
                Hinweis: Bitte ändere allgemeine Zielgruppen, KI und Job-Information in den{' '}
                <div className="link-styles" onClick={handleGoToAiSettings}>
                  Grundeinstellungen
                </div>
              </>) : (
                <>
                Best Practices: So nutzt du die Text Kl, damit du die besten Ergebnisse erhältst:{' '}
                <div className="link-styles" >
                  <a target="_blank" href="https://hilfe.meetovo.de/de/article/text-ki-best-practices-1la83i0/?bust=1725535618853">
                    Video ansehen (Dauer: 2 Min.)
                  </a>
                </div>
                </>
              )
            }
            type="info"
            showIcon
          />
        </Col>
      </Row>
      <GeneralSettingsModal
        openJobValidationModal={isGeneralSettingsModalOpen}
        setOpenJobValidationModal={setIsGeneralSettingsModalOpen}
        settingsToOpen={emptyGeneralSettings?.[0] || GeneralSettingsTabView.COMPANY_INFORMATION}
      />
    </div>
  );
};

export default AiTextSettings;
