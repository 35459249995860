const messageTypeLabelTranslations: { [key: string]: string } = {
  APPOINTMENT_REMINDER_BOOKING: 'Terminerinnerung',
  APPOINTMENT_CANCELATION_BOOKING: 'Absage',
  BOOKING_SUBMISSION_BOOKING: 'Bestätigung',
  COACH_BOOKING_SUBMISSION_BOOKING: 'Bestätigung',
  TIMED_BOOKING: 'Zeitgesteuert',
  NOT_REACHED_BOOKING: 'Nicht erreicht',
};

export const getBookingStatusLabelByMessageType = (type: string) => {
  const cleanType = type?.split('__')[0];

  return messageTypeLabelTranslations[cleanType] || 'Nicht definiert';
};